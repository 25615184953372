exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-header-engagement-title-styles__title {\n  padding: 16px;\n  background-color: var(--cps-color-ash);\n}\n", ""]);

// exports
exports.locals = {
	"title": "src-header-engagement-title-styles__title " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemBorder"] + ""
};