import fetcher, { fetchWithSharedCache, fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import canopyUrls from "canopy-urls!sofe";
import { clone } from "lodash";

export function getEngagementObservable(clientId, engagementId) {
  if (!clientId)
    throw new Error(`Cannot get engagement without being provided a clientId`);
  if (!engagementId)
    throw new Error(
      `Cannot get engagement without being provided an engagementId`
    );

  return fetchWithSharedCache(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}`,
    `/engagements/${engagementId}`
  ).pipe(pluck("engagements"));
}

export function putEngagement(clientId, engagementId, engagement) {
  if (!clientId) throw new Error(`clientId is required. Was '${clientId}'`);
  if (!engagementId)
    throw new Error(`engagementId is required. Was '${engagementId}'`);
  if (!engagement)
    throw new Error(`engagement is required. Was '${engagement}'`);
  const engagements = clone(engagement);
  // archived_at is not supported by this endpoint anymore, is_archived is used instead
  // but some of the old data returned still returns archived_at from the GET. So we delete this to avoid a 400
  delete engagements.archived_at;

  return fetcher(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}`,
    {
      method: "PUT",
      body: JSON.stringify({ engagements }),
      headers: {
        "API-Version": "v1",
      },
    }
  ).then((response) => {
    if (response.ok) {
      return response.json().then((json) => {
        return json.engagements;
      });
    } else {
      throw new Error(
        `Could not put engagement -- API returned with status ${response.status}`
      );
    }
  });
}

export function getContact(contactId) {
  return fetchAsObservable(`/api/contacts/${contactId}?include=users`).pipe(
    pluck("clients")
  );
}
