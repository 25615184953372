exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!./menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-common-menu-link-styles__root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n}\n\n.src-common-menu-link-styles__icon {\n  padding-left: 10px;\n  color: var(--cps-color-secondary-text);\n}\n\n.src-common-menu-link-styles__icon:first-child {\n  padding-left: 24px;\n}\n\n.src-common-menu-link-styles__title {\n  padding: 0 14px 0 16px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  width: 100%;\n}\n\n.src-common-menu-link-styles__bullet {\n  color: var(--cps-color-secondary-text);\n  padding-left: 10px;\n}\n\n.src-common-menu-link-styles__bullet:first-child {\n  padding-left: 33px;\n}\n\n.src-common-menu-link-styles__bold {\n  font-weight: bold;\n}\n\n.src-common-menu-link-styles__add {\n  opacity: 0;\n  margin-right: 8px;\n}\n\n.src-common-menu-link-styles__root:hover .src-common-menu-link-styles__add {\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-common-menu-link-styles__root " + require("-!../../node_modules/css-loader/index.js??ref--6-1!./menu-item.styles.css").locals["menuItem"] + "",
	"icon": "src-common-menu-link-styles__icon",
	"title": "src-common-menu-link-styles__title",
	"bullet": "src-common-menu-link-styles__bullet",
	"bold": "src-common-menu-link-styles__bold",
	"add": "src-common-menu-link-styles__add"
};