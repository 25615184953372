import {fetchAsObservable} from 'fetcher!sofe';
import {pluck} from 'rxjs/operators';

export function createNewSourceFormMultiple(
  clientId,
  engagementId,
  versionId,
  revisionId,
  sourceFormId,
  index
) {
  if (!clientId) throw new Error(`clientId is required`);
  if (!engagementId) throw new Error(`engagementId is required`);
  if (!versionId) throw new Error(`versionId is required`);
  if (!revisionId) throw new Error(`revisionId is required`);
  if (!sourceFormId) throw new Error(`sourceFormId is requires`);

  index = index || 0;

  return fetchAsObservable(
    `/clients/${clientId}/engagements/${engagementId}/versions/${versionId}-${revisionId}/source-form-multiples/${sourceFormId}:${index + 1}/sections/0/answers`,
    {
      method: "POST",
      body: {
        answers: {},
      },
    }
  ).pipe(pluck("answers"));
}
