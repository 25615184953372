import React from 'react';
import ScrollableTab from './common/scrollable-tab.component.js';
import ActiveTab from './common/active-tab.component.js';

export default class Content extends React.Component {
  render() {
    const {
      clientId,
      engagementId,
      engagement,
      engagementTitleHeight,
      tabSwitcherHeight,
      smeSwitcherHeight,
      searchHeight,
      searchString,
      expandedFormGroups,
      expandedServices,
      activeTab,
    } = this.props;
    return (
      <ScrollableTab
        engagementTitleHeight={engagementTitleHeight}
        tabSwitcherHeight={tabSwitcherHeight}
        smeSwitcherHeight={smeSwitcherHeight}
        searchHeight={searchHeight}
      >
        <ActiveTab
          {...this.props}
        />
      </ScrollableTab>
    );
  }
}
