exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-common-fixed-menu-styles__root {\n  width: 322px;\n  position: fixed;\n  background-color: var(--cps-color-bumble);\n  color: var(--cps-color-primary-text);\n  height: 100%;\n  z-index: 97;\n  border-right: 1px solid var(--cps-color-silver);\n  left: 8rem;\n}", ""]);

// exports
exports.locals = {
	"root": "src-common-fixed-menu-styles__root"
};