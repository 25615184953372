import React from 'react';
import MenuLink from '../common/menu-link.component.js';
import Services from './services.component.js';
import {hasAccess} from 'cp-client-auth!sofe';
import { getContact } from '../common/engagements.resource.js';

export default class WorkflowTab extends React.Component {
  constructor() {
    super();
    this.state = {
      contact: {}
    }
    this.boundToggleServiceExpanded = toggleServiceExpanded.bind(this);
  }

  componentDidMount() {
    this.contactSub = getContact(this.props.clientId).subscribe(res => {
      this.setState({ contact: res });
    });
  }

  componentWillUnmount() {
    this.contactSub.unsubscribe();
  }

  render() {
    const {clientId, engagementId, engagement, searchString, expandedServices, setExpandedServices} = this.props;
    const {contact} = this.state;
    const userHasAccess = hasAccess(window.loggedInUser);
    const lettersLink = `#/taxes/client/${clientId}/engagements/${engagementId}/letter-generator`;

    return (
      <div>
        <MenuLink
          icon="cps-icon-reports"
          text="Summary"
          link={`#/taxes/client/${clientId}/engagements/${engagementId}/summary`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
        {userHasAccess('letters') && contact.is_active && <MenuLink
          icon="cps-icon-letters"
          text="Letters"
          link={lettersLink}
          searchString={searchString}
          hideIfNotSearched={true}
        />}
        {userHasAccess('tasks_client_requests') && <MenuLink
          icon="cps-icon-transfer"
          text="Client requests"
          link={`#/taxes/client/${clientId}/engagements/${engagementId}/client_requests`}
          searchString={searchString}
          hideIfNotSearched={true}
        />}
        {userHasAccess('client_survey') && <MenuLink
          icon="cps-icon-survey"
          text="Client survey"
          link={`#/forms/clients/${clientId}/engagements/${engagementId}/survey`}
          searchString={searchString}
          hideIfNotSearched={true}
        />}
        <Services
          clientId={clientId}
          hasAccess={userHasAccess}
          engagementId={engagementId}
          engagement={engagement}
          toggleServiceExpanded={this.boundToggleServiceExpanded}
          expandedServices={expandedServices}
          searchString={searchString}
        />
        <MenuLink
          icon="cps-icon-lock"
          text="Archive"
          link={`#/taxes/client/${clientId}/engagements/${engagementId}/lock`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
        <MenuLink
          icon="cps-icon-reorder"
          text="Services"
          link={ `#/taxes/client/${clientId}/engagements/${engagementId}/services`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
      </div>
    );
  }
}

function toggleServiceExpanded(slug) {
  if (this.props.expandedServices.indexOf(slug) >= 0) {
    this.props.setExpandedServices(this.props.expandedServices.filter(service => service !== slug));
  } else {
    this.props.setExpandedServices(this.props.expandedServices.concat(slug));
  }
}
