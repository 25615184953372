exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-workflow-service-steps-styles__root {\n  padding: 16px 24px 11px 24px;\n}\n\n.src-workflow-service-steps-styles__step {\n  height: 27px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.src-workflow-service-steps-styles__stepName {\n  text-decoration: none;\n  color:  var(--cps-color-primary-text);\n  width: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-right: 4px;\n}\n.src-workflow-service-steps-styles__stepName:hover, .src-workflow-service-steps-styles__stepName:focus {\n  text-decoration: none;\n}\n\n.src-workflow-service-steps-styles__has2Icons {\n  min-width: 48px;\n}\n\n.src-workflow-service-steps-styles__has3Icons {\n  min-width: 72px;\n}\n\n.src-workflow-service-steps-styles__addStep {\n  margin-left: -12px !important;\n  color: var(--cps-color-primary-text) !important;\n}\n\n.src-workflow-service-steps-styles__stepActions {\n  display: none;\n}\n\n.src-workflow-service-steps-styles__step:hover .src-workflow-service-steps-styles__stepActions {\n  display: inline;\n}\n\n.src-workflow-service-steps-styles__stepAction {\n  cursor: pointer;\n  color: var(--cps-color-secondary-text)\n}\n\n.src-workflow-service-steps-styles__active {\n  font-weight: bold;\n}\n\n.src-workflow-service-steps-styles__disabled {\n  color: var(--cps-color-af);\n}\n\n.src-workflow-service-steps-styles__needs_review {\n  color: var(--cps-color-warning);\n}\n\n.src-workflow-service-steps-styles__complete {\n  color: var(--cps-color-success);\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-workflow-service-steps-styles__root " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemBorder"] + "",
	"step": "src-workflow-service-steps-styles__step",
	"stepName": "src-workflow-service-steps-styles__stepName",
	"has2Icons": "src-workflow-service-steps-styles__has2Icons",
	"has3Icons": "src-workflow-service-steps-styles__has3Icons",
	"addStep": "src-workflow-service-steps-styles__addStep",
	"stepActions": "src-workflow-service-steps-styles__stepActions",
	"stepAction": "src-workflow-service-steps-styles__stepAction",
	"active": "src-workflow-service-steps-styles__active",
	"disabled": "src-workflow-service-steps-styles__disabled",
	"needs_review": "src-workflow-service-steps-styles__needs_review",
	"complete": "src-workflow-service-steps-styles__complete"
};