import React from 'react';
import EngagementTitle from './engagement-title.component.js';
import Search from './search.component.js';
import WorkflowFormsSwitcher from './workflow-forms-switcher.component.js';
import ToggleSMEVersion from './toggle-sme-version.component.js';
import { get } from "lodash";

export default class Header extends React.Component {
  render() {
    const {engagement, newEngagementTitleHeight, newSearchString, activeTab, setActiveTab, searchString} = this.props;
    return (
      <div>
        <ToggleSMEVersion
          engagementType={engagement.forms_version}
        />
        <EngagementTitle
          engagement={engagement}
          newEngagementTitleHeight={newEngagementTitleHeight}
        />
        <Search
          newSearchString={newSearchString}
        />
        {!searchString &&
          <WorkflowFormsSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        }
        
      </div>
    );
  }
}
