import React from 'react';
import WorkflowTab from '../workflow/workflow-tab.component.js';
import FormsTab from '../forms/forms-tab.component.js';
import {partial} from 'lodash';
import styles from './workflow-forms-switcher.styles.css';

export default function WorkflowFormsSwitcher(props) {
  return (
    <div className={`${styles.root}`}>
      <a className={`${styles.tab} ${props.activeTab === WorkflowTab ? styles.active : ''}`} onClick={partial(props.setActiveTab, WorkflowTab)}>
        Work
      </a>
      <a className={`${styles.tab} ${props.activeTab === FormsTab ? styles.active : ''}`} onClick={partial(props.setActiveTab, FormsTab)}>
        Forms
      </a>
    </div>
  );
}
