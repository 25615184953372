exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-header-toggle-sme-version-styles__root {\n  background-color: var(--cp-color-nav-secondary-bg);\n  display: flex;\n  align-items: center;\n  padding: 16px 24px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-header-toggle-sme-version-styles__root " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemBorder"] + ""
};