import React from 'react';
import {get} from 'lodash';
import { UserTenantProps } from "cp-client-auth!sofe";

import Header from './header/header.component.js';
import Content from './content.component.js';
import WorkflowTab from './workflow/workflow-tab.component.js';
import FormsTab from './forms/forms-tab.component.js';

@UserTenantProps({ permissions: { hasSmePermissions: 'sme' } })
export default class Menu extends React.Component {
  constructor(props) {
    super();
    const activeTab = window.location.href.indexOf('sourceforms') >= 0 ? FormsTab : WorkflowTab;
    const activeProgramMatches = /.+\/program\/(.+)\/section\/.+/.exec(window.location.href);
    const activeProgram = get(activeProgramMatches, '[1]');
    const expandedServices = activeProgram
      ?
        props
        .engagement
        .program_data
        .programs
        .filter(program => program.slug === activeProgram)
        .map(program => program.slug)
      :
        []

    this.state = {
      activeTab,
      expandedServices: expandedServices, // stored here so that it persists when switching between tabs
      expandedFormGroups: [],
      searchString: '',
      engagementTitleHeight: 51,
      tabSwitcherHeight: 46,
      smeSwitcherHeight: props.permissions?.hasSmePermissions ? 105 : 0,
      searchHeight: 46,
    };
    this.actions = {
      newEngagementTitleHeight: height => this.setState({engagementTitleHeight: height}),
      newSearchString: searchString => this.setState({searchString}),
      setActiveTab: activeTab => this.setState({activeTab}),
      setExpandedServices: expandedServices => this.setState({expandedServices}),
      setExpandedFormGroups: expandedFormGroups => this.setState({expandedFormGroups}),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.permissions?.hasSmePermissions &&
      this.props.permissions?.hasSmePermissions
    ) {
      this.setState({ smeSwitcherHeight: 105 });
    }
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <Header
          {...this.props}
          {...this.state}
          {...this.actions}
        />
        <Content
          {...this.props}
          {...this.state}
          {...this.actions}
        />
      </div>
    );
  }
}
