import React from 'react';
import {Dialog} from 'primary-navbar!sofe';
import styles from './add-step.styles.css';
import {isEmpty} from 'lodash';
import {MAX_TITLE_LENGTH, MAX_DESCRIPTION_LENGTH} from './section.constants.js';
import {createSection} from './program-sections.resource.js';
import {catchAsyncStacktrace} from 'auto-trace';
import {CprButton} from 'canopy-styleguide!sofe';

export default class AddStep extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "",
      description: "",
      saving: false,
    };
  }
  componentDidMount() {
    this.titleRef.focus();
  }
  render() {
    const {cancel} = this.props;
    return (
      <Dialog
        dialogId={"add-engagement-program-step"}
      >
        <div className="cps-modal">
          <div className="cps-modal__screen" />
          <div className="cps-modal__dialog cps-card cps-card__height-3">
            <div className="cps-card__header cps-subheader-sm">
              <span>
                Add new step
              </span>
              <a className={`cps-modal__dialog__close cps-icon cps-icon-close ${styles.alignCloseBtn}`} onClick={cancel} />
            </div>
            <form autoComplete="off">
              <div className={`cps-card__body ${styles.body}`}>
                <div className="cps-form-group">
                  <label>
                    Step title
                    <span className="cps-primary">
                      *
                    </span>
                  </label>
                  <input
                    ref={ref => this.titleRef = ref}
                    type="text"
                    value={this.state.title}
                    required={true}
                    className="cps-form-control"
                    maxLength={MAX_TITLE_LENGTH}
                    onChange={evt => this.setState({title: evt.target.value})}
                  />
                </div>
                <div className={`cps-form-group ${styles.descriptionQuestion}`}>
                  <label>
                    Step description
                    <span className="cps-primary">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    value={this.state.description}
                    required={true}
                    className="cps-form-control"
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    onChange={evt => this.setState({description: evt.target.value})}
                  />
                </div>
              </div>
              <div className={`cps-modal__dialog__actions ${styles.action}`}>
                <CprButton
                  actionType="primary"
                  disabled={isEmpty(this.state.title) || isEmpty(this.state.description)}
                  disableOnClick={true}
                  showLoader={this.state.saving}
                  onClick={evt => performAdd.call(this, evt)}
                >
                  Add step
                </CprButton>
                <CprButton actionType="flat" onClick={cancel}>
                  Close
                </CprButton>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    );
  }
}

function performAdd(evt) {
  evt.preventDefault();
  this.setState({saving: true});

  createSection(this.props.clientId, this.props.engagementId, this.props.program.uid, this.state.title, this.state.description)
  .subscribe(
    section => {
      this.props.cancel();
      this.props.program.sections.push(section);
      window.location.href = `#/taxes/client/${this.props.clientId}/engagements/${this.props.engagementId}/program/${this.props.program.slug}/section/${section.id}`;
    },
    catchAsyncStacktrace()
  );
}
