exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-header-workflow-forms-switcher-styles__root {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.src-header-workflow-forms-switcher-styles__tab {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  color: var(--cps-color-primary-text);\n  text-decoration: none;\n  width: 100%;\n  height: 100%;\n}\n\n.src-header-workflow-forms-switcher-styles__tab:hover, .src-header-workflow-forms-switcher-styles__tab:focus {\n  text-decoration: none;\n}\n\n.src-header-workflow-forms-switcher-styles__active {\n  font-weight: 600;\n  border-bottom: 2px solid var(--cps-color-primary);\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-header-workflow-forms-switcher-styles__root " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemHeight"] + " " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemBorder"] + "",
	"tab": "src-header-workflow-forms-switcher-styles__tab",
	"active": "src-header-workflow-forms-switcher-styles__active"
};