exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css"), undefined);

// module
exports.push([module.id, ".src-header-search-styles__root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding-left: 24px;\n  cursor: pointer;\n}\n\n.src-header-search-styles__icon {\n  margin-right: 8px;\n  color: var(--cps-color-secondary-text);\n}\n\n.src-header-search-styles__input {\n  border: none;\n  outline: none;\n  font-size: 14px;\n  width: 100%;\n  margin-right: 24px;\n}\n\ninput::placeholder {\n  color: var(--cps-color-input-placeholder);\n  opacity: 1; /* for firefox */\n}\n\n.src-header-search-styles__input:focus, input:hover {\n  outline: none;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-header-search-styles__root " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemBorder"] + " " + require("-!../../node_modules/css-loader/index.js??ref--6-1!../common/menu-item.styles.css").locals["menuItemHeight"] + "",
	"icon": "src-header-search-styles__icon",
	"input": "src-header-search-styles__input"
};