import {MAX_TITLE_LENGTH, MAX_DESCRIPTION_LENGTH} from './section.constants.js';
import canopyUrls from 'canopy-urls!sofe';
import fetcher, {forceBustCache, fetchAsObservable} from 'fetcher!sofe';
import { pluck, mergeMap } from 'rxjs/operators'
import { from } from 'rxjs'

export function createSection(clientId, engagementId, programId, title, description) {
  if (!clientId) throw new Error(`Must provide client id`);
  if (!engagementId) throw new Error(`Must provide engagementId`);

  const section = newSection(title, description, engagementId, programId);

  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}/sections`, {
    method: 'post',
    body: JSON.stringify({engagement_sections: section}),
    headers: {
      'API-Version': 'v1',
    },
  }).pipe(
    pluck('engagement_sections'),
    mergeMap(json => {
      section.id = json.id;
      section.slug = Number(section.id).toString();
      return from(
        forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}`)
        .then(() => section)
      )
    })
  )
}

export function deleteSection(clientId, engagementId, sectionId) {
  if (!clientId) {
    throw new Error(`Must provide client id`);
  }
  if (!engagementId) {
    throw new Error(`Must provide engagementId`);
  }
  if (!sectionId) {
    throw new Error(`Must provide section id`);
  }

  return fetcher(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}/sections/${sectionId}`, {
    method: 'DELETE',
    headers: {
      'API-Version': 'v1',
    },
  })
  .then((response) => {
    if (response.ok) {
      return forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/engagements/${engagementId}`);
    } else {
      throw new Error(`Could not delete section. HTTP status was ${response.status}`);
    }
  });
}

function newSection(name, description, engagementId, programId) {
  if (!engagementId) {
    throw new Error(`Must provide engagementId`);
  }
  if (!programId) {
    throw new Error(`Must provide program id`);
  }
  if (typeof name !== 'string' || name.length > MAX_TITLE_LENGTH) {
    throw new Error(`'name' must be a string of max length ${MAX_TITLE_LENGTH}`);
  }

  if (typeof description !== 'string' || description.length > MAX_DESCRIPTION_LENGTH) {
    throw new Error(`'description' must be a string of max length ${MAX_DESCRIPTION_LENGTH}`);
  }

  return {
    name: name,
    description: description,
    is_removable: true,
    status: 'in_progress',
    relationships: {
      parent_engagement: {
        type: "engagement",
        id: Number(engagementId)
      },
      parent_program: {
        type: "engagement_program",
        uid: programId
      }
    }
  };
}
