import React from 'react';

export default class ScrollableTab extends React.Component {
  render() {
    const {engagementTitleHeight, tabSwitcherHeight, smeSwitcherHeight, searchHeight} = this.props;
    const heightAboveTab = engagementTitleHeight + tabSwitcherHeight + smeSwitcherHeight + searchHeight;
    const height = `calc(100% - ${heightAboveTab}px)`;
    return (
      <div style={{overflowY: 'auto', maxHeight: height, height}}>
        {this.props.children}
      </div>
    );
  }
}
