import React from 'react';
import styles from './search.styles.css';
import {trimStart} from 'lodash';

export default class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      casedSearch: '',
    };
  }
  render() {
    const {newSearchString} = this.props;
    return (
      <div className={`${styles.root}`} onClick={() => this.inputEl.focus()}>
        <i className={`cps-icon cps-icon-search ${styles.icon}`} />
        <input
          className={`${styles.input}`}
          ref={el => this.inputEl = el}
          type="text"
          value={this.state.casedSearch}
          placeholder="Search forms or steps"
          onChange={evt => {
            this.setState({casedSearch: evt.target.value});
            const searchString = evt.target.value.toLowerCase();
            // we want to be able to reset the search with "", but not allow whitespace chars as a real search
            if (searchString === "" || searchString.trim() !== "") {
              newSearchString(searchString);
            }
          }}
        />
      </div>
    );
  }
}
