import {fetchAsObservable} from 'fetcher!sofe';
import {pluck} from 'rxjs/operators'

export function getSourceFormGroups(clientId, engagementId, versionId, revisionId) {
  if (!clientId) throw new Error(`clientId is required`);
  if (!engagementId) throw new Error(`engagementId is required`);
  if (!versionId) throw new Error(`versionId is required`);
  if (!revisionId) throw new Error(`revisionId is required`);

  return fetchAsObservable(`/clients/${clientId}/engagements/${engagementId}/versions/${versionId}-${revisionId}/groups`)
    .pipe(pluck('groups'));
}
