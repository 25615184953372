exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-common-menu-item-styles__menuItemBorder {\n  border-bottom: 1px solid var(--cps-color-silver);\n}\n\n.src-common-menu-item-styles__menuItemHeight {\n  height: 46px;\n  min-height: 46px;\n}\n\n.src-common-menu-item-styles__menuItem {\n  text-decoration: none;\n  color: var(--cps-color-primary-text);\n  outline: none;\n}\n\n.src-common-menu-item-styles__menuItem:hover, .src-common-menu-item-styles__menuItem:focus {\n  text-decoration: none;\n  outline: none;\n}\n\n.src-common-menu-item-styles__menuItem:hover {\n  background-color: var(--cps-color-ash);\n}\n", ""]);

// exports
exports.locals = {
	"menuItemBorder": "src-common-menu-item-styles__menuItemBorder",
	"menuItemHeight": "src-common-menu-item-styles__menuItemHeight",
	"menuItem": "src-common-menu-item-styles__menuItem src-common-menu-item-styles__menuItemBorder src-common-menu-item-styles__menuItemHeight"
};