import React from 'react';
import styles from './service-steps.styles.css';
import AddStep from './add-step.component.js';
import DeleteStep from './delete-step.component.js';
import SearchedString from '../common/searched-string.component.js';
import {putEngagement} from '../common/engagements.resource.js';
import {catchAsyncStacktrace} from 'auto-trace';
import {CprButton} from 'canopy-styleguide!sofe';

const sectionColors = {
  needs_review: styles.needs_review,
  complete: styles.complete,
};

export default class ServiceSteps extends React.Component {
  constructor() {
    super();
    this.state = {
      addStepModal: false,
      deleteStep: {
        showModal: false,
        idOfStep: null,
      },
    };
  }
  render() {
    const {program, clientId, engagementId, searchString} = this.props;
    return (
      <div className={`${styles.root}`}>
        {
          program.sections.map((section, index) => (
            <div className={`${styles.step}`} key={section.id}>
              <a
                className={`${styles.stepName} ${isActive(program, section) ? styles.active : ''}`}
                href={getSectionHref(clientId, engagementId, program.slug, section)}
              >
                <SearchedString
                  text={section.name}
                  searchString={searchString}
                  className={sectionColors[section.status] || ""}
                />
              </a>
              <span className={`${styles.stepActions} ${numIconsClass(section)}`}>
                {isRemovable(section) &&
                  <i
                    className={`cps-icon cps-icon-sm-close ${styles.stepAction}`}
                    onClick={() => this.setState({deleteStep: {showModal: true, idOfStep: section.id}})}
                  />
                }
                <i
                  className={`cps-icon cps-icon-move-up ${styles.stepAction} ${canMoveUp(index) ? '' : styles.disabled}`}
                  onClick={() => canMoveUp(index) && moveSectionUp.call(this, index)}
                />
                <i
                  className={`cps-icon cps-icon-move-down ${styles.stepAction} ${canMoveDown(index, program) ? '' : styles.disabled}`}
                  onClick={() => canMoveDown(index, program) && moveSectionDown.call(this, index)}
                />
              </span>
            </div>
          ))
        }
        <CprButton actionType="flat" className={styles.addStep} onClick={() => this.setState({addStepModal: true})}>
          <span>
            + Add step
          </span>
        </CprButton>
        {this.state.addStepModal &&
          <AddStep
            clientId={clientId}
            engagementId={engagementId}
            program={program}
            cancel={() => this.setState({addStepModal: false})}
          />
        }
        {this.state.deleteStep.showModal &&
          <DeleteStep
            clientId={clientId}
            engagementId={engagementId}
            program={program}
            stepId={this.state.deleteStep.idOfStep}
            cancel={() => this.setState({deleteStep: {showModal: false, idOfStep: null}})}
          />
        }
      </div>
    );
  }
}

function getSectionHref(clientId, engagementId, programSlug, section) {
  return section.slug === 'analytics'
    ? `#/forms/clients/${clientId}/engagements/${engagementId}/collections/analytics/offer`
    : `#/taxes/client/${clientId}/engagements/${engagementId}/program/${programSlug}/section/${section.slug || section.id}`;
}

function isActive(program, section) {
  if (program.slug === 'collections' && section.slug === 'analytics') {
    return window.location.href.indexOf('collections/analytics') >= 0;
  } else {
    return window.location.href.indexOf(`/program/${program.slug}/section/${section.id}`) >= 0 || window.location.href.indexOf(`/program/${program.slug}/section/${section.slug}`) >= 0;
  }
}

function numIconsClass(section) {
  return isRemovable(section) ? styles.has3Icons : styles.has2Icons;
}

function canMoveUp(sectionIndex) {
  return sectionIndex !== 0;
}

function canMoveDown(sectionIndex, program) {
  return sectionIndex !== program.sections.length - 1;
}

function isRemovable(section) {
  return section.is_removable;
}

function moveSectionDown(index) {
  const {program, clientId, engagementId, engagement} = this.props;
  const sectionToMove = program.sections[index];
  program.sections[index] = program.sections[index + 1];
  program.sections[index + 1] = sectionToMove;

  putEngagement(clientId, engagementId, engagement)
  .catch(catchAsyncStacktrace());

  /* Once putEngagement comes back it will cause a setState in a parent component.
   * This is just optimistic that the putEngagement will succeed so that the UI
   * appears faster.
   */
  this.forceUpdate();
}

function moveSectionUp(index) {
  const {program, clientId, engagementId, engagement} = this.props;
  const sectionToMove = program.sections[index];
  program.sections[index] = program.sections[index - 1];
  program.sections[index - 1] = sectionToMove;

  putEngagement(clientId, engagementId, engagement)
  .catch(catchAsyncStacktrace());

  /* Once putEngagement comes back it will cause a setState in a parent component.
   * This is just optimistic that the putEngagement will succeed so that the UI
   * appears faster.
   */
  this.forceUpdate();
}
