exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-workflow-add-step-styles__body {\n  padding-bottom: 0;\n}\n\n.src-workflow-add-step-styles__descriptionQuestion {\n  margin-bottom: 0;\n}\n\n.src-workflow-add-step-styles__action {\n  padding-left: 24px;\n}\n\n.src-workflow-add-step-styles__alignCloseBtn {\n  top: 0;\n}\n", ""]);

// exports
exports.locals = {
	"body": "src-workflow-add-step-styles__body",
	"descriptionQuestion": "src-workflow-add-step-styles__descriptionQuestion",
	"action": "src-workflow-add-step-styles__action",
	"alignCloseBtn": "src-workflow-add-step-styles__alignCloseBtn"
};