import React from 'react';
import { taxResVersionAsObservable } from 'sme-version!sofe';
import {catchAsyncStacktrace} from 'auto-trace';

export default class FetchSMEVersion extends React.Component {
  constructor() {
    super();
    this.state = {
      smeVersion: null,
      smeRevision: null,
    };
  }
  componentDidMount() {
    this.subscription = taxResVersionAsObservable.subscribe(
      data => {
        this.setState({
          smeVersion: data.version,
          smeRevision: data.revision,
        });
      },
      catchAsyncStacktrace(),
    );
  }
  render() {
    return this.props.children({smeVersion: this.state.smeVersion, smeRevision: this.state.smeRevision});
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
}
