import React from 'react';
import {isEmpty} from 'lodash';
import {getMatch} from './search.utils.js';

export default class SearchedString extends React.Component {
  render() {
    const {text, searchString, className=""} = this.props;
    if (isEmpty(searchString)) {
      return <span className={className}>{text}</span>
    }

    const match = getMatch(text, searchString);
    const highlightStartIndex = match.startIndex;
    const highlightEndIndex = match.endIndex;
    return highlightStartIndex >= 0
      ?
        <span className={className}>
          <span>
            {text.slice(0, highlightStartIndex)}
          </span>
          <span style={{color: "var(--cps-color-primary)"}}>
            {text.slice(highlightStartIndex, highlightEndIndex)}
          </span>
          <span>
            {text.slice(highlightEndIndex)}
          </span>
        </span>
      :
        <span className={className}>{text}</span>
  }
}
