import React from 'react';
import FixedMenu from './common/fixed-menu.component.js';
import WithUrlParams from './common/with-url-params.component.js';
import FetchEngagement from './common/fetch-engagement.component.js';
import Menu from './menu.component.js';
import { ErrorBoundary } from 'error-logging!sofe';
import {hot} from 'react-hot-loader';
import {UserTenantProps} from 'cp-client-auth!sofe'

@hot(module)
@ErrorBoundary({featureName: 'engagements-menu'})
@UserTenantProps({permissions: {hasEngagements: 'tasks_engagements'}, waitForData: true})
export default class EngagementsMenuRoot extends React.Component {
  render() {
    if (this.props.permissions && !this.props.permissions.hasEngagements) {
      console.info('The loggedInUser does not have "tasks_engagements", redirecting from engagements-menu')
      window.history.replaceState(null, "", `/#/403`);
    }
    return (
      <FixedMenu>
        <WithUrlParams>
          {({clientId, engagementId, newUrlParams}) => (
            <FetchEngagement
              clientId={clientId}
              engagementId={engagementId}
              newUrlParams={newUrlParams}
            >
              {({engagement}) => engagement && (
                <Menu
                  clientId={clientId}
                  engagementId={engagementId}
                  newUrlParams={newUrlParams}
                  engagement={engagement}
                />
              )}
            </FetchEngagement>
          )}
        </WithUrlParams>
      </FixedMenu>
    );
  }
}
