import React from 'react';
import SourceFormGroup from './source-form-group.component.js';
import SourceForm from './source-form.component.js';
import IApologizeForTrying from './i-apologize-for-trying.component.js';
import {includes, get, keys, pickBy, identity, property, partial} from 'lodash';
import {stringMatchesSearch} from '../common/search.utils.js';

export default class FormsTab extends React.Component {
  constructor(props) {
    super();
    const activeSourceFormMatches = /.+\/sourceforms\/([^\/]+)\/(?:index|sections)\/.+/.exec(window.location.href);
    const activeSourceFormId = get(activeSourceFormMatches, '[1]');
    const activeGroups = props.sourceFormGroups.reduce(partial(toActiveGroups, []), []);
    props.setExpandedFormGroups(activeGroups.concat(props.expandedFormGroups));

    function toActiveGroups(parentGroups, activeGroups, group) {
      const initialSourceForm = includes(activeSourceFormId, ":")
        ? activeSourceFormId.substring(0, activeSourceFormId.indexOf(":"))
        : activeSourceFormId;

      if (group.forms.some(form => form.meta.formId === initialSourceForm)) {
        return [...parentGroups, group.id];
      } else {
        return group.groups.reduce(partial(toActiveGroups, [...parentGroups, group.id]), activeGroups);
      }
    }
  }
  render() {
    const {
      sourceFormGroups,
      expandedFormGroups,
      setExpandedFormGroups,
      clientId,
      engagementId,
      activeSourceForm,
      searchString,
      addFormMultiple,
    } = this.props;
    const groupsToDisplay = sourceFormGroups.find(group => group.name === "Resolution Forms Tab") || {id: 'Top level group', groups: sourceFormGroups, forms: []};

    return groupsToDisplay
      ?
        <div>
          {groupsToDisplay.groups.map(group => (
            <SourceFormGroup
              key={group.id}
              group={group}
              addFormMultiple={addFormMultiple}
              expandedFormGroups={[...expandedFormGroups, groupsToDisplay.id]}
              expandGroup={expandGroup.bind(this)}
              collapseGroup={collapseGroup.bind(this)}
              indentationLevel={0}
              clientId={clientId}
              engagementId={engagementId}
              activeSourceForm={activeSourceForm}
              searchString={searchString}
              groupVisibility={groupsToDisplay.groups.reduce(toVisibilityMap, {})}
              parentForceVisible={false}
            />
          ))}
          {groupsToDisplay.forms.map(form => (
            <SourceForm
              key={form.meta.formId + form.index}
              clientId={clientId}
              formId={form.meta.formId}
              addFormMultiple={addFormMultiple}
              engagementId={engagementId}
              sourceForm={form}
              indentationLevel={0}
              activeSourceForm={activeSourceForm}
              searchString={searchString}
              parentForceVisible={false}
            />
          ))}
        </div>
      :
        <IApologizeForTrying
          message="In the SME tool, there is no top-level source form group called 'Resolution Forms Tab'"
        />


      function toVisibilityMap(result, group) {
        // Stuff needed to calculate visibility
        const subGroupMap = group.groups.reduce(toVisibilityMap, {});
        const groupMatchesSearch = !!searchString && stringMatchesSearch(group.name, searchString);
        const subGroupForcesVisibility = keys(pickBy(subGroupMap, childVisibility => {
          return childVisibility.forceChildrenVisible || childVisibility.hasVisibleChild || childVisibility.formsForceVisibility
        })).length > 0;

        // Visibility values
        const formsCouldBeVisible = group.forms.some(property("visible"));
        const formsForceVisibility = !!searchString && formsCouldBeVisible && group.forms.some(form => stringMatchesSearch(form.name, searchString));
        const forceChildrenVisible = !!searchString && groupMatchesSearch && (formsCouldBeVisible || group.groups.length > 0);
        const hasVisibleChild = subGroupForcesVisibility || formsForceVisibility;
        const subGroupCouldBeVisible = keys(pickBy(subGroupMap, childVisibility => {
          return childVisibility.subGroupCouldBeVisible || childVisibility.formsCouldBeVisible;
        })).length > 0;
        return {
          ...result,
          ...subGroupMap,
          [group.id]: {
            name: group.name,
            forceChildrenVisible,
            hasVisibleChild,
            formsForceVisibility,
            formsCouldBeVisible,
            subGroupCouldBeVisible,
          },
        };
      }
  }
}

function expandGroup(id) {
  this.props.setExpandedFormGroups([...this.props.expandedFormGroups, id]);
}

function collapseGroup(id) {
  this.props.setExpandedFormGroups(this.props.expandedFormGroups.filter(groupId => groupId !== id));
}
